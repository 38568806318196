// temporary
import React from "react"
import loadable from "@loadable/component"
import { v4 as uuid } from "uuid"
import LazyHydrate from "react-lazy-hydration"
import { Link } from "gatsby"
import HomepageLayout from "components/layouts/HomepageLayout"
import ScrollLoadLayout from "components/layouts/ScrollLoadLayout"
import Container from "components/elements/Container"
import FeaturedDeals from "components/elements/FeaturedDeals"

export function findComponentElement(name, data, collections) {
  let products = []

  switch (name) {
    case "products.product-listing":
      const ProductListing = loadable(() =>
        import("components/layouts/ProductListing")
      )

      const targetCollection =
        collections.length > 0
          ? collections.find(
              (collection) =>
                collection.shopifyId === data.collection.shopifyData.value
            )
          : null

      // Shopify will not return collections with no products
      products = targetCollection ? targetCollection : { products: [] }

      // TODO handle url creation for shopifyData.type === tag
      let collectionUrl = "/"
      if (
        data?.collection &&
        data?.collection.shopifyData.type === "collection"
      ) {
        collectionUrl = `/collections/${data.collection.shopifyData.handle}`
      }

      return (
        <HomepageLayout lazy={true}>
          <LazyHydrate noWrapper whenVisible>
            <ProductListing
              products={products.products.slice(0, data.productLimit)}
              scrollable={data.scrollListing}
              listingDetails={{
                title: data.title,
                desc: data.description,
                url: collectionUrl,
                linkText: data.linkText,
                linkBottom: data.scrollListing,
              }}
            />
          </LazyHydrate>
        </HomepageLayout>
      )

    case "products.features":
      const FeaturedProducts = loadable(() =>
        import("components/layouts/FeaturedProducts")
      )

      const title = "Explore the latest coffee essentials." // Add title to Strapi!!
      const featuredProducts = data.Tab.map((tab) => {
        const targetCollection =
          collections.length > 0
            ? collections.find(
                (collection) =>
                  collection.shopifyId === tab.collection.shopifyData.value
              )
            : null

        // Shopify will not return collections with no products
        const tabProducts = targetCollection ? targetCollection.products : []

        return {
          type: tab.title,
          products: tabProducts.slice(0, 4),
        }
      })

      return (
        <HomepageLayout lazy={true}>
          <div className="mt-24">
            <LazyHydrate noWrapper whenVisible>
              <FeaturedProducts
                title={title}
                featuredProducts={featuredProducts}
              />
            </LazyHydrate>
          </div>
        </HomepageLayout>
      )

    case "products.sales":
      const featuredDeals = {
        primary: {
          title: data.primary.Title,
          desc: data.primary.Description,
          savings: data.primary.Savings,
          linkText: data.primary.LinkText,
          image: data.primary.FeatureImage,

          // depending on whether it is available, show either data.primary.collection.shopifyData.handle or data.primary.Page.handle
          linkUrl: data.primary.collection
            ? `collections/${data.primary.collection.shopifyData.handle}`
            : data.primary.Page
            ? `${data.primary.Page.handle}`
            : `collections/${data.primary.linkUrl}`,
        },
        secondary: data.secondary.map((item) => {
          return {
            title: item.Title,
            desc: item.Description,
            logo: item.Logo,
            linkText: item.LinkText,
            image: item.Image,
            linkUrl: item.collection
              ? `collections/${item.collection.shopifyData.handle}`
              : item.page?.handle
              ? `${item.Page.handle}`
              : `${item.LinkText}`,
          }
        }),
      }
      return (
        <>
          <FeaturedDeals key={uuid()} featuredDeals={featuredDeals} />
        </>
      )
    case "homepage.attributes":
      const FeaturePanelGrid = loadable(() =>
        import("components/layouts/FeaturePanelGrid")
      )

      return (
        <HomepageLayout lazy={true}>
          <LazyHydrate noWrapper whenVisible>
            <FeaturePanelGrid
              title={data.title}
              desc={data.description}
              titleLeft={data.textLeft}
              items={data.attribute}
            />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "images.carousel":
      const ImageCarousel = loadable(() =>
        import("components/elements/Carousel")
      )

      return (
        <HomepageLayout layout="full" lazy={true}>
          <LazyHydrate noWrapper whenVisible>
            <ImageCarousel images={data.slides.map((slide) => slide.url)} />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "homepage.services":
      const ServiceGrid = loadable(() =>
        import("components/layouts/ServiceGrid")
      )

      const services = data.servicePages.map((service) => {
        return {
          title: service.Title,
          description: service.description,
          image: service.image,
          linkText: "Learn more",
          url: service.handle,
        }
      })
      return (
        <HomepageLayout lazy={true}>
          <LazyHydrate noWrapper whenVisible>
            <ServiceGrid services={services} />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "homepage.blog":
      const BlogRow = loadable(() => import("components/layouts/BlogRow"))

      return (
        <HomepageLayout lazy={true}>
          <BlogRow title={data.title} handle={"/blog"} posts={data.articles} />
        </HomepageLayout>
      )
    case "customers.testimonials":
      // console.log(data)
      const Testimonials = loadable(() =>
        import("components/elements/Testimonials")
      )

      return (
        <HomepageLayout lazy={true}>
          <LazyHydrate noWrapper whenVisible>
            <Testimonials testimonials={data.testimonialSlides} />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "subscription.coffee":
      const CoffeeSubscription = loadable(() =>
        import("components/elements/CTA")
      )

      return (
        <HomepageLayout layout="full">
          <LazyHydrate noWrapper whenVisible>
            <CoffeeSubscription {...data} />
          </LazyHydrate>
        </HomepageLayout>
      )

    case "brands.grid":
      const BrandGrid = loadable(() => import("components/layouts/BrandGrid"))

      const brands = data.brands.map((brand) => {
        return {
          title: brand.title,
          image: brand.image.url,
          handle: brand?.collection?.shopifyData?.handle,
        }
      })
      return (
        <HomepageLayout lazy={true}>
          <LazyHydrate noWrapper whenVisible>
            <BrandGrid title={data.title} brands={brands} />
            <div className="hidden md:flex flex-col justify-end float-right">
              <Link
                className="text-lg font-bold flex flex-row text-idc-title hover:text-idc-orange"
                to="/brands"
              >
                Show all brands
                <div className="flex flex-col justify-center ml-2">
                  <svg
                    width="15"
                    height="15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.673 1.698 13.475 7.5l-5.802 5.802M13.185 7.5H1"
                      stroke="#EF4223"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
              </Link>
            </div>
          </LazyHydrate>
        </HomepageLayout>
      )

    case "categories.grid":
      const CategoryGrid = loadable(() =>
        import("components/layouts/CategoryGrid")
      )

      const categories = data.categories.map((category) => {
        return {
          title: category.title,
          image: category.image,
          handle: category?.collection?.shopifyData?.handle,
          onSale: Boolean(category?.onSale),
        }
      })

      return (
        <HomepageLayout lazy={true}>
          <LazyHydrate noWrapper whenVisible>
            <CategoryGrid title={data.title} categories={categories} />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "page-content.heading":
      const PageHeading = loadable(() =>
        import("components/elements/StrapiPageContent/PageHeading")
      )

      return <PageHeading text={data.text} />

    case "page-content.image":
      const PageHeadingImage = loadable(() =>
        import("components/elements/StrapiPageContent/PageHeadingImage")
      )

      return (
        <HomepageLayout>
          <LazyHydrate noWrapper whenVisible>
            <PageHeadingImage imageUrl={data?.image} />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "page-content.content":
      const PageContent = loadable(() =>
        import("components/elements/StrapiPageContent/PageContent")
      )

      return (
        <HomepageLayout>
          <LazyHydrate noWrapper whenVisible>
            <PageContent content={data.content} />
          </LazyHydrate>
        </HomepageLayout>
      )

    case "newsletter.sign-up-form":
      const SignUpForm = loadable(() =>
        import("components/elements/SignUpForm")
      )

      return (
        <Container key={uuid()}>
          <SignUpForm {...data} />
        </Container>
      )

    case "embed.yotpo-module":
      const YotpoModule = loadable(() =>
        import("components/elements/Embeds/YotpoModule")
      )

      return (
        <ScrollLoadLayout>
          <div key={uuid()}>
            <YotpoModule {...data} />
          </div>
        </ScrollLoadLayout>
      )

    case "brands.logo-cloud":
      const LogoCloud = loadable(() => import("components/elements/LogoCloud"))

      return (
        <div key={uuid()}>
          <LogoCloud {...data} />
        </div>
      )

    default:
  }
}
