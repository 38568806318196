import { Link } from "gatsby"
import React, { FC } from "react"
import { v4 as uuid } from "uuid"
import { Helmet } from "react-helmet"

import StrapiImage from "components/elements/StrapiImage"
import { getResourceUrl } from "utils/strapi"

export interface FeaturedDealPrimaryProps {
  title: string
  desc: string
  savings: number
  linkUrl: string
  linkText: string
  image: string
}

export interface FeaturedDealSecondaryProps {
  title: string
  desc: string
  image: string
  logo?: string
  linkUrl: string
}

export interface FeaturedDealProps {
  primary: FeaturedDealPrimaryProps
  secondary?: FeaturedDealSecondaryProps[]
}
export interface FeaturedDealsProps {
  featuredDeals: FeaturedDealProps
}

const FeaturedDeals: FC<FeaturedDealsProps> = ({ featuredDeals }) => {
  const { primary, secondary } = featuredDeals

  const handleClick = (linkUrl, title) => {
    if (typeof window === "undefined" || !window?.klaviyo) return null
    const klaviyo = window.klaviyo
    const item = {
      SourceURL: "https://www.idrinkcoffee.com/",
      DestinationURL: linkUrl,
      BannerTitle: title,
    }
    klaviyo.push(["track", "Clicked Banner", item])
  }

  return (
    <div
      style={{
        background: "linear-gradient(180deg, #F6F4F1 80%, #FFF 80%)",
      }}
      className="relative flex flex-row justify-center px-2 pt-4 herosection md:px-4 lg:px-8 lg:pt-10"
    >
      <h1 className="sr-only">iDrinkCoffee.com Roasters and Merchancts</h1>
      <div className="w-full max-w-[76.125rem]">
        <h2 className="sr-only">Featured Deals</h2>
        <div>
          <div className="flex flex-col-reverse md:flex-row">
            <div className="relative flex flex-row items-center justify-center px-4 py-6 md:px-6 shrink-0 md:w-1/3 min-h-72">
              <div className="relative text-center">
                <div className="relative z-10">
                  <h3 className="m-0 mb-2 text-3xl font-bold md:text-4xl text-idc-title">
                    {primary.title}
                  </h3>
                  <p className="m-0 text-base font-normal leading-7 md:text-xl text-idc-body">
                    {primary.desc}
                  </p>

                  {primary.savings ? (
                    <>
                      <p className="m-0 mt-4 text-sm font-extrabold uppercase lg:mt-6 text-idc-orange">
                        Save up to
                      </p>
                      <div className="flex flex-row justify-center text-idc-title">
                        <span className="font-bold text-xl sm:text-[1.75rem] leading-none m-0">
                          $
                        </span>
                        <p className="font-extrabold text-7xl leading-[3.5rem] m-0">
                          {primary.savings}
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <Link
                    to={`${primary.linkUrl}`}
                    className="inline-block px-8 py-3 mt-6 transition duration-300 ease-in-out rounded-full bg-idc-orange hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue"
                    onClick={() => () =>
                      handleClick(primary.linkUrl, primary.title)(
                        primary.linkUrl,
                        primary.title
                      )}
                  >
                    <span className="m-0 text-base font-bold text-white">
                      {primary.linkText}
                    </span>
                  </Link>
                </div>
                <div
                  aria-disabled="true"
                  aria-hidden="true"
                  className="absolute -translate-x-1/2 -translate-y-1/2 rounded-full select-none top-1/2 left-1/2 lg:bg-white md:bg-white w-72 h-72"
                ></div>
              </div>
            </div>
            <div className="relative flex flex-col justify-center w-full md:pl-2">
              {primary.image ? (
                <>
                  <Helmet>
                    <link
                      rel="preload"
                      as="image"
                      href={`${
                        primary.image.formats?.small
                          ? getResourceUrl(primary.image.formats?.thumbnail.url)
                          : getResourceUrl(primary.image.url)
                      }`}
                      imagesrcset={`
                        ${
                          primary.image.formats?.small
                            ? `${getResourceUrl(
                                primary.image.formats?.small.url
                              )} 500w,`
                            : ``
                        }
                        ${
                          primary.image.formats?.medium
                            ? `${getResourceUrl(
                                primary.image.formats?.medium.url
                              )} 750w,`
                            : ``
                        }
                        ${
                          primary.image.formats?.large
                            ? `${getResourceUrl(
                                primary.image.formats?.large.url
                              )} 1000w,`
                            : ``
                        }
                        ${
                          primary.image.formats?.xlarge
                            ? `${getResourceUrl(
                                primary.image.formats?.xlarge.url
                              )} 1920w,`
                            : ``
                        }
                      `}
                    />
                  </Helmet>
                  <Link
                    to={`${primary.linkUrl}`}
                    onClick={() => handleClick(primary.linkUrl, primary.title)}
                  >
                    {" "}
                    <StrapiImage
                      image={primary.image}
                      className="aspect-[870/488] rounded-2xl w-full h-full object-cover"
                      loading="eager"
                      alt={primary.title}
                      fetchpriority="high"
                    />
                  </Link>
                </>
              ) : (
                ""
              )}
              {/* <img src={getResourceUrl(primary.image)} className="aspect-[870/488] rounded-2xl w-full h-full object-cover" alt="" loading="eager" /> */}
            </div>
          </div>
          <div className="grid gap-6 pt-6 md:grid-cols-3" style={{}}>
            {secondary
              ? secondary.map((deal) => (
                  <Link
                    className="group"
                    key={uuid()}
                    to={`${deal.linkUrl}`}
                    onClick={() => handleClick(deal.linkUrl, deal.title)}
                  >
                    <div>
                      {primary.image ? (
                        <StrapiImage
                          image={deal.image}
                          alt={deal.title}
                          className="w-full rounded-2xl aspect-[384/230]"
                          fetchpriority="low"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="relative">
                      <div className="float-right mr-2 -mt-6 lg:-mt-8 lg:mr-3">
                        <div className="rounded-full border-2 lg:border-[3px] border-idc-white bg-idc-blue group-hover:bg-idc-orange active:text-[#F47761] transition duration-300 ease-in-out flex items-center justify-center w-12 lg:w-16 h-12 lg:h-16">
                          <span className="sr-only">View {deal.title}</span>
                          <svg
                            className="w-[21px] h-[15px] lg:w-[26px] lg:h-[19px]"
                            viewBox="0 0 26 19"
                            fill="none"
                            xplns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M17.868 0.93934C17.2822 0.353553 16.3325 0.353553 15.7467 0.93934C15.1609 1.52513 15.1609 2.47487 15.7467 3.06066L20.8487 8.1626H2C1.17157 8.1626 0.5 8.83417 0.5 9.6626C0.5 10.491 1.17157 11.1626 2 11.1626H20.8489L15.7467 16.2648C15.1609 16.8506 15.1609 17.8004 15.7467 18.3862C16.3325 18.9719 17.2822 18.9719 17.868 18.3862L25.5308 10.7234C26.1166 10.1376 26.1166 9.18787 25.5308 8.60209L17.868 0.93934Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>

                      <div className="pt-3 pl-3 md:pl-5 md:pt-4">
                        {deal.logo ? (
                          <div className="relative h-6 mb-2">
                            <img
                              src={getResourceUrl(deal.logo.url)}
                              className="absolute object-contain h-full"
                              alt={deal.logo.alternativeText}
                              loading="lazy"
                            />
                          </div>
                        ) : null}
                        <h3 className="m-0 text-xl font-bold leading-tight transition duration-300 ease-in-out md:text-2xl text-idc-title md:leading-tight group-hover:text-idc-orange">
                          {deal.title}
                        </h3>
                        <p className="mt-1 text-base text-idc-body">
                          {deal.desc}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedDeals
