import React from "react"
import { graphql } from "gatsby"
import { Layout } from "components/layouts/Layout/layout"
import { findComponentElement } from "utils/findComponent"
import { Seo } from "components/seo"

const cmsPage = ({ data, pageContext }) => {
  const pageData = pageContext.main
  const { title, description, image } = pageContext
  const collections = data.collections.edges.map((edge) => {
    return {
      products: edge.node.products.map((product) => {
        return {
          ...product,
          reviews: data.reviews.nodes.find(
            (review) => review.handle === product.handle
          ),
        }
      }),
      shopifyId: edge.node.shopifyId,
    }
  })

  return (
    <>
      {image?.localFile?.url ? (
        <Seo
          title={title}
          description={description}
          image={image.localFile.url}
        />
      ) : (
        <Seo title={title} description={description} />
      )}
      {pageData.map((item, index) => {
        return (
          <React.Fragment key={`${item.strapi_component}-${index}`}>
            {findComponentElement(item.strapi_component, item, collections)}
          </React.Fragment>
        )
      })}
    </>
  )
}

export const query = graphql`
  query PageQuery($shopifyIds: [String!]!) {
    collections: allShopifyCollection(
      filter: { shopifyId: { in: $shopifyIds } }
    ) {
      edges {
        node {
          id
          handle
          description
          shopifyId
          legacyResourceId
          image {
            originalSrc
            transformedSrc
          }
          title
          products(limit: 12) {
            handle
            title
            legacyResourceId
            images {
              id
              altText
              gatsbyImageData(layout: CONSTRAINED, width: 320)
            }
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            productType
            variants {
              compareAtPrice
              price
            }
            tags
          }
        }
      }
    }
    reviews: allIdcProductsJson {
      nodes {
        handle
        meta {
          yotpo {
            reviews_average
          }
        }
      }
    }
  }
`

export default cmsPage
